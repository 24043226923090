import { useQuery } from '@apollo/client';
import { chakra, Button, Center, Spinner, Text, useDisclosure, UnorderedList, ListItem, Link, Box  } from '@chakra-ui/react';
import moment from 'moment';

import {
  QUERY_PUBLISHED_NEWSLETTERS,
} from '../../Queries';

import { useUser } from '../../Context';
import { LoginModal } from '../../Components';
import { useEffect, useState } from 'react';

export const Newsletters = () => {
  const { user } = useUser();
  const { isOpen: isLoginOpen, onClose: onLoginClose, onOpen: onLoginOpen } = useDisclosure();

  const [time, setTime] = useState(0);

  useEffect(() => {
    setTime(moment().unix());
  }, []);

  const { loading, data } = useQuery(QUERY_PUBLISHED_NEWSLETTERS, {
    skip: !user || !time,
    variables: {
      start: 0,
      end: time,
      public_only: user === undefined
    }
  });

  const newsletter_url = data?.queryPublishedNewsletters?.newsletters?[0].newsletter_details.newsletter_url : null;

  return (
    !user ? (
      <>
        <Text>
          <Button
            as={'a'}
            variant={'link'}
            onClick={onLoginOpen}>Login</Button> to view the Newsletters.
        </Text>
        <LoginModal isOpen={isLoginOpen} onClose={onLoginClose} />
      </>
    ) : (
      loading ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        // <Box>
        //     <Box>
        //         <chakra.object data={newsletter_url} type="application/pdf" width="100%" height="100%" />
        //     </Box>
            <Box>
                <UnorderedList>
                  {data?.queryPublishedNewsletters?.newsletters?.map((newsletter, index) =>
                    <ListItem key={index}>
                      <Link href={newsletter.newsletter_details.newsletter_url} isExternal>{newsletter.newsletter_details.newsletter_name}</Link>
                    </ListItem>
                  )}
                </UnorderedList>
            </Box>
        //</Box>
      )
    )
  )
};
